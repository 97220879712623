<!-- 底部 -->
<template>
    <div class="footer">
        <div class="footer-content">
            <div class="company">
                <p>成都优麦互联网医院有限公司</p>
                <p>电话：400-662-8089</p>
                <p>地址：四川省成都市双流区成都芯谷产业园区集中区内</p>
            <div class="footer-panel">CopyRight 2021 成都优麦互联网医院有限公司版权所有   <a target="_blank" href="https://beian.miit.gov.cn" style="color: white;">蜀ICP备2021005216号-1</a></div>
            
            
            </div>
            <div class="qrcode">
                <img src="http://umaterials.oss-cn-shenzhen.aliyuncs.com/bcae9101-57ef-11ec-9f33-215d22155fd3.png" alt="">
                <div>关注公众号</div>
            </div>
        </div>
        
    </div>
  
</template>

<script>
import { reactive,toRefs,onBeforeMount,onMounted} from 'vue'
export default {
    name: '',
      setup() {
          console.log('1-开始创建组件-setup')
          const data = reactive({

          })
          onBeforeMount(() => {
              console.log('2.组件挂载页面之前执行----onBeforeMount')
          })
          onMounted(() => {
              console.log('3.-组件挂载到页面之后执行-------onMounted')
          })
          const refData = toRefs(data);
          return {
              ...refData,
          }

      }
  };
</script>
<style lang='scss' scoped>
.footer{
    background: #040f1b;
    padding: 20px;
    color: white;
    font-size: 14px;
    &-panel{
        
    }
    &-content{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;
        .company{
            text-align: left;
            p{
                margin: 10px 0;
            }
        }
        .qrcode{
            img{
                width: 120px;
                height: 120px;
            }
        }
    }
}
</style>