<!--  -->
<template>
  <div>我是详情页面</div>
</template>

<script>
import { reactive,toRefs,onBeforeMount,onMounted, onUnmounted,onActivated} from 'vue'
import {useRouter, useRoute} from 'vue-router'
export default {
    name: 'detail',
      setup() {
          console.log('1-开始创建组件-setup')
          const router = useRouter()
          const route = useRoute()
          const data = reactive({

          })
          onBeforeMount(() => {
              console.log('2.组件挂载页面之前执行----onBeforeMount')
          })
          onMounted(() => {
              console.log(router,route.params)
              console.log('3.-组件挂载到页面之后执行-------onMounted')
          })
          onActivated(()=>{
              console.log('detail-keep-alive')
          })
          onUnmounted(()=>{
              console.log('卸载')
          })
          const refData = toRefs(data);
          return {
              ...refData,
          }
            
      },
      mounted(){
          

      },
      activated(){
          console.log('keep-alive')
      }
  };
</script>
<style lang='scss' scoped>
</style>

