<!-- 首页 -->
<template>
  <Header></Header>
  <div class="body-panel">
    <p class="article-title">产品介绍</p>
    <div class="body-panel-one">
      <div>
        <img
          src="http://umaterials.oss-cn-shenzhen.aliyuncs.com/509068f0-57f8-11ec-9f33-215d22155fd3.png"
          alt=""
        />
      </div>
      <div>
        <img
          src="http://umaterials.oss-cn-shenzhen.aliyuncs.com/580dd450-57f8-11ec-9f33-215d22155fd3.png"
          alt=""
        />
      </div>
      <div>
        <img
          src="http://umaterials.oss-cn-shenzhen.aliyuncs.com/6adf0770-57f8-11ec-9f33-215d22155fd3.png"
          alt=""
        />
      </div>
      <div>
        <img
          src="http://umaterials.oss-cn-shenzhen.aliyuncs.com/707fc391-57f8-11ec-9f33-215d22155fd3.png"
          alt=""
        />
      </div>
    </div>
  </div>
  <div class="body-panel">
    <p class="article-title">下载云医院</p>
    <div class="body-panel-two">
      <div>
        <img
          src="http://umaterials.oss-cn-shenzhen.aliyuncs.com/a8f8b421-57f8-11ec-9f33-215d22155fd3.png"
          alt=""
        />
        <p>安卓下载</p>
      </div>
      <div>
        <img
          src="http://umaterials.oss-cn-shenzhen.aliyuncs.com/a0cf2a41-57f8-11ec-9f33-215d22155fd3.png"
          alt=""
        />
        <p>IOS下载</p>
      </div>
    </div>
  </div>
  <!-- <div style="" @click="godetail()">去详情</div> -->
  <Footer></Footer>
</template>

<script>
import {
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  onActivated,
  onDeactivated,
} from "vue";
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "home",
  components: {
    Header,
    Footer,
  },

  setup() {
    console.log("1-开始创建组件-setup");
    const data = reactive({});
    onBeforeMount(() => {
      console.log("2.组件挂载页面之前执行----onBeforeMount");
    });
    onMounted(() => {
      console.log("3.-组件挂载到页面之后执行-------onMounted");
    });
    onActivated(() => {
      console.log("home-keep-alive");
    });
    onDeactivated(() => {
      console.log("dekeep-alive");
    });
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
  mounted() {},
  methods: {
    godetail: function () {
      this.$router.push("/detail/123");
    },
  },
};
</script>
<style lang='scss' scoped>
.body-panel {
  padding: 20px;
  .article-title {
    font-size: 40px;
    border-top: 1px solid #f5f5f5;
    padding-top: 50px;
    font-weight: bold;
  }
  
  &-one {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    padding: 50px 0 150px;
    & > div {
      width: 22%;
      border-radius: 15px;
      box-shadow: 2px 0 10px 1px #b6b6b6;
      transition: all 0.2s;
    }
    img {
      display: block;
      width: 100%;
      border-radius: 15px;
      transition: all 0.2s;
    }
    & > div:hover {
      box-shadow: 2px 0 12px 4px #b6b6b6;
    }
  }
  &-two {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px;
    &>div{
        
        width: 20%;
        img{
            width: 100%;
        }
    }
    &>div:first-child{
        img{
            width: 95%;
        }
    }
  }
}
</style>
