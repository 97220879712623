<!-- 头部组件 -->
<template>
  <div class="header-panel">
      <img src="http://umaterials.oss-cn-shenzhen.aliyuncs.com/403495e1-57e8-11ec-9f33-215d22155fd3.png" alt="">
      <div class="header-panel-title">优麦云医院</div>
  </div>
</template>

<script>
import { reactive,toRefs,onBeforeMount,onMounted} from 'vue'
export default {
    name: 'Header',
      setup() {
          const data = reactive({

          })
          onBeforeMount(() => {
          })
          onMounted(() => {
          })
          const refData = toRefs(data);
          return {
              ...refData,
          }

      }
  };
</script>
<style lang='scss' scoped>
.header-panel{
    display: flex;
    align-items: center;
    height: 90px;
    position: sticky;
    top: 0;
    background: rgba(0,0,0,.1);
    padding: 0 16px;
    img{
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }
    &-title{
        font-size: 22px;
        font-weight: bold;
        margin-left: 20px;
        color: #333;
    }
    
}
</style>