import { createRouter, createWebHistory } from 'vue-router'

import Home from  '../page/home.vue';
import Detail from '../page/detail.vue'
export default createRouter({
    history: createWebHistory(),
    routes: [
        {path:'/'    ,name:'index',component: Home},
        {path:'/home',name:'Home',component: Home},
        {path:'/detail/:id',name:'detail',component: Detail},
    ]
});